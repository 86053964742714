body {
  background: #FFFFFF !important;
  color: #808285;
}

/* LEFT COL */
.site_title.site_title--no-padding {
  padding-left: 0;
}

.left_col {
  background-color: #256ba7;
}

.nav_title {
  background-color: #256ba7 !important;
}

.nav-sm ul.nav.child_menu {
  background: #004179;
}

.nav.child_menu > li > a,
.nav.side-menu > li > a {
  color: #fff;
}

.nav-sm .nav.child_menu li.active,
.nav-sm .nav.side-menu li.active-sm {
  border-right: 5px solid #fff;
}

li.active-sm {
  background-color: #004179;
}

.nav.side-menu > li.active > a {
  background: #004179;
}

li.active {
  background: #004179;
}

.nav.side-menu > li.active,
.nav.side-menu > li.current-page {
  border-right: 5px solid #fff;
}

.nav-md ul.nav.child_menu li:before {
  background: #fff;
}

.nav-md ul.nav.child_menu li:after {
  border-left: 1px solid #fff !important;
}

.fa-chevron-down {
  color: #FFFFFF !important;
}

.sidebar-footer {
  background: #868686;
}

.sidebar-footer a {
  background: #e0e0e0;
}

.sidebar-footer a:hover {
  background: #868686;
}

/* TOP NAV */

.nav_menu {
  background-color: #e0e0e0;
}

.dropdown-menu > li > a {
  color: #808285;
}

.top_nav .nav .open>a, .top_nav .nav .open>a:focus, .top_nav .nav .open>a:hover, .top_nav .nav>li>a:focus, .top_nav .nav>li>a:hover {
  background: #bcc9da;
}
/* CONTENT */

#main_content {
  background-color: #fff;
}

.x_content {
  background-color: #fff;
}

a {
  color: #58595B;
}

ul.panel_toolbox .btn-box-tool {
  color: #808285 !important;
}

.breadcrumb > .active {
  background-color: #fff;
}

//.table-striped > tbody > tr:nth-of-type(odd):hover {
//  background-color: #F1FAFF;
//}
//
//.table tr.even:hover {
//  background-color: #F1FAFF;
//}
//
//.table tr.selected {
//  background: #E0F1DB !important;
//}

/*DATATABLE COLLAPSED BUTTON */

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  width: 20px;
  height: 20px;
  background-color: #256ba7;
  line-height: 20px;
}
//
//table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
//table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
//  background-color: #E9496B;
//}

.confirmation-email-failed {
  background-color: #F7BFBD !important;
}

/*TOP PROGRESS BAR */

#nprogress .bar {
  background: #256ba7;
}

/* TOP RIGHT LOADING SPINNER */

#nprogress .spinner-icon {
  border-top-color: #256ba7;
  border-left-color: #256ba7;
}

/* BOOTSTRAP */

//.text-danger {
//  color: #E9496B;
//}
//
//.has-error .checkbox,
//.has-error .checkbox-inline,
//.has-error .control-label,
//.has-error .help-block,
//.has-error .radio,
//.has-error .radio-inline,
//.has-error.checkbox label,
//.has-error.checkbox-inline label,
//.has-error.radio label,
//.has-error.radio-inline label {
//  color: #E9496B;
//}
//
//.has-error .form-control {
//  border-color: #E9496B !important;
//}

/* BUTTONS */

//.btn-success {
//background-color: rgb(13,104,60);
//border-color: rgb(13,104,60);
//}
//
//.btn-success:hover,
//.btn-success:focus,
//.btn-success:active,
//.btn-success.active,
//.open > .dropdown-toggle.btn-success {
//background: rgb(9,84,63);
//border-color: rgb(9,84,63);
//}
//
//.btn-success:active,
//.btn-success.active {
//background: rgb(5,63,35) !important;
//border-color: rgb(5,63,35) !important;
//box-shadow: none;
//}
//
//.btn-primary {
//background-color: #0098f9;
//border-color: #0098f9;
//}
//
//.btn-primary:hover,
//.btn-primary:focus,
//.btn-primary:active,
//.btn-primary.active,
//.open > .dropdown-toggle.btn-primary {
//background: #35a1de;
//border-color: #35a1de;
//}
//
//.btn-primary:active,
//.btn-primary.active {
//background: #2f8cc1 !important;
//border-color: #2f8cc1 !important;
//box-shadow: none;
//}
//
//.btn-info {
//background-color: #EC7C1D;
//border-color: #EC7C1D;
//}
//
//.btn-info:hover,
//.btn-info:focus,
//.btn-info:active,
//.btn-info.active,
//.open > .dropdown-toggle.btn-info {
//background: #e0771d;
//border-color: #e0771d;
//}
//
//.btn-info:active,
//.btn-info.active {
//background: #c76a1b !important;
//border-color: #c76a1b !important;
//box-shadow: none;
//}
//
//.btn-danger {
//background-color: #E9496B;
//border-color: #E9496B;
//}
//
//.btn-danger:hover,
//.btn-danger:focus,
//.btn-danger:active,
//.btn-danger.active,
//.open > .dropdown-toggle.btn-danger {
//background: #ca405d;
//border-color: #ca405d;
//}
//
//.btn-danger:active,
//.btn-danger.active {
//background: #af3650 !important;
//border-color: #af3650 !important;
//box-shadow: none;
//}

/* ALERTS */

//.alert-success {
//  background-color: #6EC562;
//  border-color: #6EC562;
//}
//
//.alert-danger {
//  background-color: #E9496B;
//  border-color: #E9496B;
//}
//
//.alert-info {
//  background-color: #EC7C1D;
//  border-color: #EC7C1D;
//}

.tooltip-inner {
  background-color: #3A3A3C !important;
  /*!important is not necessary if you place custom.css at the end of your css calls. For the purpose of this demo, it seems to be required in SO snippet*/
  color: #fff;
}

.tooltip.top .tooltip-arrow {
  border-top-color: #3A3A3C;
}

.tooltip.right .tooltip-arrow {
  border-right-color: #3A3A3C;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #3A3A3C;
}

.tooltip.left .tooltip-arrow {
  border-left-color: #3A3A3C;
}

/* Bootbox classes */

.bootbox-modal .modal-body {
  text-align: center;
  font-size: 14px;
}

/* data tables */

.table.oscus-table thead tr {
  background: #0C518A;
  color: white;
}

.table.oscus-table tbody tr:nth-child(even) {
  background: #eaeaea;
}

td.day.disabled {
  background-color: #ededed!important;
}

/*end data tables*/

table.table thead {
  background-color: #2e6da4;
  color: white;
}

/* BackBtn button css */
#returnBtn {
  position: fixed;
  right: 0px;
  top: 58px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #354e5f;
  color: white;
  display: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  z-index: 9999;
}

#returnBtn i {
  padding-top: 9px;
  padding-left: 6px;
}

#returnBtn:hover {
  background-color: #94bc3f;
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)";
}